import { formatTimeLabel } from 'lib/datetime'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { useFlag } from 'hooks'
import { EventFormat } from 'graphql/generated'
import FieldLabel from 'components/common/FieldLabel'
import { Toggle, Typography } from 'components/common'
import TextField from 'components/common/TextField'

interface IProps {
  restrictFieldChange: boolean
  startTime: Date
  setStartTime: CallbackWithParam<Date>
  endTime: Date
  setEndTime: CallbackWithParam<Date>
  capacity: number
  setCapacity: CallbackWithParam<number>
  format: EventFormat
  setRecordingState: CallbackWithParam<boolean>
  recordingState: boolean
}

const ATTENDEES_RANGE = 200

function EventDetailsStep({
  restrictFieldChange,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  capacity,
  setCapacity,
  recordingState,
  setRecordingState,
  format
}: IProps) {
  const [limitAttendees, setLimitAttendees] = useState<boolean>(capacity > 0)
  useEffect(() => {
    setCapacity(limitAttendees ? ATTENDEES_RANGE : 0)
  }, [limitAttendees, setCapacity])

  const minimumDate = useMemo(
    () => moment().add(1, 'minute').startOf('minute').toDate(),
    []
  )
  const minimumEndDate = startTime

  const totalTimeLabels = useMemo(
    () =>
      formatTimeLabel(
        Math.ceil((endTime!.getTime() - startTime!.getTime()) / 1000)
      ),
    [endTime, startTime]
  )

  const defaultIntroductionTime = useFlag(
    'EVENTS:END_TIME_PADDING_MINUTES',
    format !== EventFormat.IN_PERSON ? 10 : 60
  )
  useEffect(() => {
    if (moment(endTime).isBefore(startTime)) {
      setEndTime(
        moment(minimumEndDate).add(defaultIntroductionTime, 'minutes').toDate()
      )
    } else if (moment(endTime).isBefore(minimumEndDate)) {
      setEndTime(minimumEndDate)
    }
  }, [startTime, endTime, setEndTime, minimumEndDate, defaultIntroductionTime])

  return (
    <div className="flex-1 flex flex-col overflow-auto space-y-5">
      <div className="space-y-4 border-b border-gray-200 pb-5">
        <div className="flex justify-between items-center gap-4">
          <FieldLabel label="Start time:" />

          {restrictFieldChange ? (
            <Typography size="sm">
              {moment(startTime).format('LLLL zz')}
            </Typography>
          ) : (
            <div className="flex">
              <DatePicker
                preventOpenOnFocus
                showTimeSelect
                minDate={minimumDate}
                selected={startTime}
                onChange={date =>
                  // @ts-ignore
                  setStartTime(moment(date).startOf('minute').toDate())
                }
                dateFormat="MMMM d, yyyy h:mm aa"
                wrapperClassName="flex-1"
                className="rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:w-64 text-sm cursor-pointer"
                popperPlacement="top-end"
                popperModifiers={[
                  {
                    name: 'preventOverflow',
                    options: {
                      rootBoundary: 'viewport',
                      tether: false,
                      altAxis: true
                    }
                  }
                ]}
              />
            </div>
          )}
        </div>

        <div className="flex justify-between items-center gap-4">
          <FieldLabel label="End time:" className="mt-1" />

          {restrictFieldChange ? (
            <Typography size="sm">
              {moment(endTime).format('LLLL zz')}
            </Typography>
          ) : (
            <div className="flex">
              <DatePicker
                preventOpenOnFocus
                showTimeSelect
                minDate={minimumEndDate}
                selected={endTime}
                // @ts-ignore
                onChange={date =>
                  // @ts-ignore
                  setEndTime(moment(date).startOf('minute').toDate())
                }
                dateFormat="MMMM d, yyyy h:mm aa"
                className="rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:w-64 text-sm cursor-pointer"
                popperPlacement="top-end"
                popperModifiers={[
                  {
                    name: 'preventOverflow',
                    options: {
                      rootBoundary: 'viewport',
                      tether: false,
                      altAxis: true
                    }
                  }
                ]}
              />
            </div>
          )}
        </div>

        <div className="text-right">
          <span className="text-gray-500 text-sm font-light">{`Total event time:\u2004`}</span>
          <span className="text-gray-800 text-sm font-semibold">
            {totalTimeLabels}
          </span>
        </div>
      </div>

      {format !== EventFormat.IN_PERSON && (
        <div className="border-b border-gray-200 pb-5">
          <Toggle
            label="Automatically record Event"
            disabled={restrictFieldChange}
            checked={recordingState}
            onChange={setRecordingState}
          />
        </div>
      )}

      <div className="flex flex-col gap-2">
        <Toggle
          label="Limit attendees"
          checked={limitAttendees}
          onChange={setLimitAttendees}
        />

        {limitAttendees && (
          <TextField
            label={`Attendee Limit (between 1 and ${ATTENDEES_RANGE})`}
            type="number"
            className="sm:w-64"
            value={!limitAttendees ? '' : capacity}
            onChange={e => {
              const val = e.target.value ? parseInt(e.target.value) : 1
              if (val < 1) {
                setCapacity(1)
              } else if (val > ATTENDEES_RANGE) {
                setCapacity(ATTENDEES_RANGE)
              } else {
                setCapacity(val)
              }
            }}
          />
        )}
      </div>
    </div>
  )
}

export default EventDetailsStep
