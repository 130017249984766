import Thumbnail from 'components/Thumbnail'
import { components } from 'react-select'
import { StyledSelect } from 'components/common/Select'
import useCollectives from 'hooks/collectives/useCollectives'
import { Collective } from 'graphql/generated'
import { useCallback } from 'react'

interface IProps {
  disabled?: boolean
}

function CollectivePicker<T extends boolean = false>({
  disabled = false,
  onChange,
  multiple,
  value
}: IProps &
  (
    | {
        value: Maybe<Array<string>>
        onChange: CallbackWithParam<string[]>
        multiple?: true
      }
    | {
        value: Maybe<string>
        onChange: CallbackWithParam<string>
        multiple?: false
      }
  )) {
  const { edges, loading } = useCollectives()
  const collectives = edges?.map(edge => edge.node) || []

  const selectedCollectives = multiple
    ? collectives.filter(o => value?.includes(o.id))
    : collectives.find(c => c.id === value)

  const onSelect = useCallback(
    value => (multiple ? onChange(value.map(v => v.id)) : onChange(value?.id)),

    [multiple, onChange]
  )

  return (
    <StyledSelect<Pick<Collective, 'id' | 'name' | 'thumb'>, T>
      minHeight={46}
      menuListMaxHeight={175}
      isLoading={loading}
      options={collectives}
      value={selectedCollectives}
      placeholder="Select DAO"
      onChange={onSelect}
      isClearable
      isDisabled={disabled}
      isMulti={multiple as T}
      components={{
        Option: v => (
          <components.Option {...v} isSelected={false}>
            <div className="px-2 flex space-x-2 items-center">
              <Thumbnail
                src={v.data.thumb}
                initials={v.data.name.substring(0)}
                className="h-8 w-8 rounded-full overflow-hidden"
              />
              <div className="flex-1 text-sm font-light">{v.data.name}</div>
            </div>
          </components.Option>
        ),
        SingleValue: v => (
          <components.SingleValue {...v}>
            <div className="flex space-x-2 items-center">
              <Thumbnail
                src={v.data.thumb}
                initials={v.data.name.substring(0)}
                className="h-8 w-8 rounded-full overflow-hidden"
              />
              <div className="flex-1 text-sm font-light">{v.data.name}</div>
            </div>
          </components.SingleValue>
        ),
        MultiValue: v => (
          <components.MultiValue {...v}>
            <div className="flex space-x-2 items-center">
              <Thumbnail
                src={v.data.thumb}
                initials={v.data.name.substring(0)}
                className="h-6 w-6 rounded-full overflow-hidden"
              />
              <div className="flex-1 text-sm font-light">{v.data.name}</div>
            </div>
          </components.MultiValue>
        )
      }}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
    />
  )
}

export default CollectivePicker
