import {
  EventFieldsFragment,
  EventFormat,
  GeolocationObjectInput,
  useCreateEventMutation,
  useUpdateEventMutation
} from 'graphql/generated'
import { useQueryErrorHandler } from 'hooks'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { getVisibilityForEvent } from './helpers'

interface IProps {
  eventId: Maybe<string>
  title: string
  description: string
  startTime: Date
  introductionTime: number
  capacity: number
  format: EventFormat
  location: Maybe<string>
  geolocation: Maybe<GeolocationObjectInput>
  collectiveId: Maybe<string>
  discoverable: boolean
  shareable: boolean
  seriesId: Maybe<string>
  recordingState: boolean
}

function useSaveEvent({
  eventId,
  title,
  description,
  startTime,
  introductionTime,
  capacity,
  format,
  location,
  geolocation,
  collectiveId,
  discoverable,
  shareable,
  seriesId,
  recordingState
}: IProps) {
  const [submitted, setSubmitted] = useState(false)
  const [saved, setSaved] = useState(false)

  const { push } = useRouter()
  const onError = useQueryErrorHandler(
    `Failed to save Event. Please try again later.`
  )

  const [createEvent, { loading: creating }] = useCreateEventMutation({
    variables: {
      event: {
        title: title.trim(),
        description: description.trim(),
        startTime,
        format,
        capacity,
        introductionTime,
        location,
        geolocation: geolocation
          ? {
              latitude: geolocation.latitude,
              longitude: geolocation.longitude
            }
          : null,
        collectiveId,
        discoverable,
        shareable,
        seriesId,
        recording: recordingState
      }
    },
    onCompleted: resp => {
      setSubmitted(true)

      const eventDAO = resp.createEvent?.collective
      window.analytics.trackAll('Event - Create Event - Complete', {
        eventId: resp.createEvent?.id || 'NA',
        nameOfEvent: title.trim(),
        type: resp.createEvent
          ? getVisibilityForEvent(resp.createEvent)
          : undefined,
        daoId: eventDAO?.id,
        daoName: eventDAO?.name
      })

      if (resp.createEvent) {
        setSaved(true)
        push(resp.createEvent.publicUrl)
      }
    },
    onError: err => {
      onError(err)
      window.analytics.trackAll('Event - Create Event - Error', {
        nameOfEvent: title.trim(),
        type: getVisibilityForEvent({
          collective: collectiveId
            ? ({ id: collectiveId } as EventFieldsFragment['collective'])
            : undefined,
          discoverable
        }),
        daoId: collectiveId
      })
    }
  })

  const [updateEvent, { loading: updating }] = useUpdateEventMutation({
    variables: {
      // @ts-ignore
      eventId,
      event: {
        title: title.trim(),
        description: description.trim(),
        startTime,
        format,
        capacity,
        introductionTime,
        location,
        geolocation: geolocation
          ? {
              latitude: geolocation.latitude,
              longitude: geolocation.longitude
            }
          : null,
        collectiveId,
        discoverable,
        shareable,
        seriesId,
        recording: recordingState
      }
    },
    onCompleted: resp => {
      setSubmitted(true)

      const eventDAO = resp.updateEvent?.collective
      window.analytics.trackAll('Event - Edit Event - Complete', {
        eventId,
        nameOfEvent: title.trim(),
        type: resp.updateEvent
          ? getVisibilityForEvent(resp.updateEvent)
          : undefined,
        daoId: eventDAO?.id,
        daoName: eventDAO?.name
      })

      if (resp.updateEvent) {
        setSaved(true)
        push(resp.updateEvent.publicUrl)
      }
    },
    onError: err => {
      onError(err)

      window.analytics.trackAll('Event - Edit Event - Error', {
        eventId,
        nameOfEvent: title.trim(),
        type: getVisibilityForEvent({
          collective: collectiveId
            ? ({ id: collectiveId } as EventFieldsFragment['collective'])
            : undefined,
          discoverable
        }),
        daoId: collectiveId
      })
    }
  })

  const saving = creating || updating
  const save = () => (eventId ? updateEvent() : createEvent())

  return { save, saving, saved, submitted }
}

export default useSaveEvent
