import MainSelect, { GroupBase, Props, StylesConfig } from 'react-select'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'
import FieldLabel, { AdditionalFieldLabelProps } from './FieldLabel'
import tailwind from 'lib/tailwind'

const menuPortalTarget = typeof document !== 'undefined' ? document.body : null

interface IProps {
  width?: number | string
  height?: number | string
  minWidth?: number | string
  minHeight?: number | string
  menuListMaxHeight?: number | string
  fontSize?: number
  label?: string
  labelProps?: Partial<AdditionalFieldLabelProps>
  layout?: 'vertical' | 'horizantal'
  containerClassName?: string
  labelClassName?: string
  styles?: StylesConfig
  required?: boolean
  loadMore?: () => void
}

export function StyledSelect<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  className,
  width,
  height,
  minHeight,
  minWidth,
  menuListMaxHeight = 200,
  fontSize,
  label,
  labelProps,
  layout = 'vertical',
  containerClassName,
  labelClassName,
  required,
  loadMore,
  styles,
  ...rest
}: IProps & Props<Option, IsMulti, Group>) {
  return (
    <div
      className={twMerge(
        'flex',
        layout === 'vertical' ? 'flex-col' : 'items-center justify-between',
        containerClassName
      )}
    >
      {label && (
        <FieldLabel
          {...labelProps}
          label={label}
          className={labelClassName}
          required={required}
        />
      )}

      <MainSelect<Option, IsMulti, Group>
        styles={{
          control: (base, styleProps) => ({
            ...base,
            width,
            height,
            minWidth,
            minHeight, //TODO: maybe use controlProps here
            cursor: 'pointer',
            ...styles?.control?.(base, styleProps)
          }),
          menuPortal: (base, styleProps) => ({
            ...base,
            zIndex: 9999,
            ...styles?.menuPortal?.(base, styleProps)
          }),
          menuList: (base, styleProps) => ({
            ...base,
            maxHeight: menuListMaxHeight,
            ...styles?.menuList?.(base, styleProps)
          }), //TODO: maybe use menuListProps here
          singleValue: (base, styleProps) => ({
            ...base,
            fontSize,
            ...styles?.singleValue?.(base, styleProps)
          }),
          multiValue: (base, styleProps) => ({
            ...base,
            fontSize,
            borderRadius: 4,
            background: tailwind.theme?.backgroundColor?.['gray'][100],
            ...styles?.multiValue?.(base, styleProps)
          }),
          placeholder: (base, styleProps) => ({
            ...base,
            fontSize,
            ...styles?.placeholder?.(base, styleProps)
          }),
          multiValueRemove: (base, styleProps) => ({
            ...base,
            background: tailwind.theme?.backgroundColor?.['red'][0],
            marginLeft: 5,
            '&:hover': {
              background: tailwind.theme?.backgroundColor?.['red'][500],
              color: 'white'
            },
            ...styles?.multiValueRemove?.(base, styleProps)
          })
        }}
        {...rest}
        className={classNames('styledSelect', className)}
        menuPortalTarget={menuPortalTarget}
        menuPlacement={'auto'}
        onMenuScrollToBottom={loadMore}
      />
    </div>
  )
}
