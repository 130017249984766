import classes from 'classnames'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Loader } from '@googlemaps/js-api-loader'
import React, { useCallback, useEffect, useState } from 'react'
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from 'react-places-autocomplete'
import useToasts from 'hooks/useToasts'
import TextField from 'components/common/TextField'

interface IProps {
  value: string
  onChange: CallbackWithParam<string>
  onSelect?: CallbackWithParams<
    string,
    {
      lat: number

      lng: number
    }
  >
  placeholder?: string
  limitScope?: 'cities'
  error?: boolean
}

function LocationInput({
  value,
  onChange,
  onSelect,
  limitScope,
  placeholder = 'Search Places...',
  error = false
}: IProps) {
  const [ready, setReady] = useState(false)
  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY!,
      libraries: ['places']
    })

    loader.load().then(() => setReady(true))
  }, [])

  const { addToast } = useToasts()
  const onError = useCallback(
    (status: string) => addToast(status, { appearance: 'warning' }),
    [addToast]
  )
  const onSelectHandler = useCallback(
    async (address: string, placeId: string) => {
      if (!onSelect) {
        return
      }

      const results = await geocodeByPlaceId(placeId)
      if (!results || !results.length) {
        return
      }

      const coordinates = await getLatLng(results[0])
      onSelect(address, coordinates)
    },
    [onSelect]
  )

  if (!ready) {
    return <p>Loading Maps...</p>
  }

  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      onSelect={onSelectHandler}
      onError={onError}
      searchOptions={{ types: limitScope === 'cities' ? ['(cities)'] : [] }}
      shouldFetchSuggestions={value.length > 2}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="relative">
          <div className="relative">
            <TextField
              {...getInputProps({})}
              label="Event Location"
              placeholder={placeholder}
              error={error}
              required
            />

            {loading && (
              <div className="absolute bottom-2 right-2">
                <FontAwesomeIcon icon={faSpinner} spin size={'lg'} />
              </div>
            )}
          </div>

          {suggestions.length > 0 && (
            <div className="absolute z-50 space-y-2 left-2 bg-white border border-gray-200 rounded-lg overflow-hidden">
              {suggestions.map(suggestion => {
                const className = classes(
                  'p-2 min-w-xs max-w-lg cursor-pointer',
                  suggestion.active ? 'bg-gray-100' : 'bg-white'
                )

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                    key={`location-${suggestion.placeId}`}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default LocationInput
