import ShareButton, {
  IProps as IShareButtonProps
} from 'components/shared/ShareButton/ShareButton'
import { EventFieldsFragment } from 'graphql/generated'

interface IProps extends Omit<IShareButtonProps, 'item'> {
  event: Pick<EventFieldsFragment, 'title' | 'publicUrl' | '__typename'>
  context?: 'InEvent' | 'NotInEvent'
}

function EventShareButton({
  event,
  context = 'NotInEvent',
  color = 'white',
  ...props
}: IProps) {
  return (
    <ShareButton
      item={event}
      color={color}
      {...props}
      message={
        context === 'NotInEvent'
          ? {
              twitter: `Check out ${event.title} on Upstream. Wanted to share so you can join it too!`,
              facebook: `Check out ${event.title} on Upstream. Wanted to share so you can join it too!`,
              linkedIn: `Check out ${event.title} on Upstream. You can RSVP to attend at the link here!`,
              dm: `Hey! Check out ${event.title} on Upstream, I think you'd love it. Click here to RSVP to attend`
            }
          : {
              twitter: `I'm at ${event.title} happening now on Upstream. Wanted to share so you can join it too!`,
              facebook: `Loving ${event.title} happening now on Upstream. You can join the event too at this link:`,
              linkedIn: `Loving ${event.title} happening now on Upstream. You can join the event too at this link:`,
              dm: `Hey! I'm at ${event.title} happening now on Upstream and think you'd love it. Click here to join the event too.`
            }
      }
    />
  )
}

export default EventShareButton
