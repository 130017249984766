import { EventFormat } from 'graphql/generated'
import { useMemo } from 'react'
import { TEventInputStep } from './EventInputStepper'
import { TVisibility } from './EventVisibilityStep'

function useCanContinue(
  visibility: Maybe<TVisibility>,
  collectiveId: Maybe<string>,
  step: TEventInputStep,
  format: EventFormat,
  title: string,
  description: string,
  location: Maybe<string>,
  saving: boolean,
  saved: boolean
) {
  return useMemo(() => {
    if (saving || saved) {
      return false
    }

    if (step === 'VISIBILITY' && !!visibility) {
      return (
        ![TVisibility.COLLECTIVE, TVisibility.COLLECTIVE_PUBLIC].includes(
          visibility
        ) || !!collectiveId
      )
    } else if (step === 'INFO' && !!format && !!title && !!description) {
      return format !== EventFormat.IN_PERSON || !!location
    } else if (step === 'DETAILS') {
      return true
    }

    return false
  }, [
    visibility,
    collectiveId,
    step,
    format,
    title,
    description,
    location,
    saving,
    saved
  ])
}

export default useCanContinue
