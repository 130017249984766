import moment from 'moment-timezone'
import { useEventContext } from 'context/EventContext'
import {
  EventState,
  EventRegistrationRSVP,
  EventFormat,
  EventFieldsFragment
} from 'graphql/generated'
import Link from 'next/link'
import { useIsLoggedIn } from 'hooks'
import RSVPButton from './RSVPButton'
import EventShareButton from 'components/shared/ShareButton/EventShareButton'
import React from 'react'
import Button from 'components/Button'
import { twMerge } from 'tailwind-merge'
import { faShare } from '@fortawesome/pro-solid-svg-icons'

export function doesUserHaveEventPermissions(event: EventFieldsFragment) {
  return (
    (!event.collective && (event.discoverable || event.shareable)) ||
    (event.collective &&
      (event.collective.membership?.approved ||
        event.discoverable ||
        event.shareable))
  )
}

export function useShowJoinButton() {
  const isLoggedIn = useIsLoggedIn()
  const { event } = useEventContext()
  const hasPermission = doesUserHaveEventPermissions(event)
  const hasEnded = event.state === EventState.ENDED

  return (
    isLoggedIn &&
    !hasEnded &&
    (event.rsvp === EventRegistrationRSVP.YES ||
      moment().isAfter(moment(event.startTime))) &&
    // in-person events shouldn't show this button
    (event.format === EventFormat.MIXER ||
      event.format === EventFormat.HOLIDAY_MIXER) &&
    hasPermission
  )
}

function EnterEventButton() {
  const { event } = useEventContext()

  return (
    <div className="bg-white py-2 flex flex-col justify-center items-center w-full">
      <div className="bg-blue-100 py-2 px-4 rounded-lg mb-2">
        <p className="text-sm">
          {event.canJoinNotice ||
            `Ready to join this event stream? Tap Enter below`}
        </p>
      </div>

      <div className="flex flex-row w-full pt-2">
        <EventShareButton event={event} size="lg" icon={faShare} />

        <div className="flex-1 ml-4">
          {event.canJoinVideo ? (
            <Link href={`${event.publicUrl}/video`} passHref>
              <Button className="h-full w-full" label="Enter Event" size="lg" />
            </Link>
          ) : (
            <div className="flex">
              {/* Shows the dropdown for rsvp button */}
              <RSVPButton
                label=""
                event={event}
                className="h-full rounded-r-none"
              />
              <Button
                disabled
                color="darkgray"
                label={`Enter Event`}
                className={twMerge(
                  !event.isOwner && 'rounded-l-none',
                  'h-full w-full'
                )}
                size={'lg'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EnterEventButton
