import BaseModal from 'components/modals/BaseModal'
import {
  Collective,
  EventFieldsFragment,
  EventFormat,
  GeolocationObjectInput
} from 'graphql/generated'
import EventInputStepper, { TEventInputStep } from './EventInputStepper'
import EventVisibilityStep, { TVisibility } from './EventVisibilityStep'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Button from 'components/Button'
import EventInfoStep from './EventInfoStep'
import { useControlledInput, useFlag } from 'hooks'
import EventDetailsStep from './EventDetailsStep'
import moment from 'moment'
import useSaveEvent from './useSaveEvent'
import useCanContinue from './useCanContinue'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { getVisibilityForEvent } from './helpers'

interface IProps {
  event?: EventFieldsFragment
  collective?: Maybe<Pick<Collective, 'id'>>
  onClose?: Callback
}

export function EventInputModal({ event, onClose }: IProps) {
  // Event Visibility
  const [visibility, setVisibility] = useState<Maybe<TVisibility>>(
    getVisibilityForEvent(event)
  )
  const [collectiveId, setCollectiveId] = useState<Maybe<string>>(
    event?.collective?.id
  )
  const [shareable, setShareable] = useState(event?.shareable || false)

  // Event Info
  const [format, setFormat] = useState<EventFormat>(
    event?.format || EventFormat.MIXER
  )
  const [location, setLocation] = useState<Maybe<string>>(event?.location)
  const [geolocation, setGeolocation] = useState<Maybe<GeolocationObjectInput>>(
    event?.geolocation
  )
  const [title, setTitle] = useControlledInput(event?.title || '')
  const [description, setDescription] = useControlledInput(
    event?.description || ''
  )
  const [seriesId, setSeriesId] = useState<Maybe<string>>(event?.series?.id)
  const [recordingState, setRecordingState] = useState<boolean>(
    !event ? true : event?.recording
  )

  useEffect(() => {
    if (format !== EventFormat.IN_PERSON) {
      setRecordingState(!event ? true : event?.recording)
    } else {
      setRecordingState(false)
    }
  }, [event, format])

  // Event Details
  const defaultIntroductionTime = useFlag(
    'EVENTS:END_TIME_PADDING_MINUTES',
    format !== EventFormat.IN_PERSON ? 10 : 60
  )
  const [capacity, setCapacity] = useState<number>(event?.capacity || 0)
  const [startTime, setStartTime] = useState<Date>(
    event
      ? moment(event.startTime).toDate()
      : moment().add(1, 'minute').startOf('minute').toDate()
  )
  const [endTime, setEndTime] = useState<Date>(
    moment(startTime)
      .add(event ? event.introductionTime : defaultIntroductionTime, 'minutes')
      .toDate()
  )
  const introductionTime = useMemo(
    () =>
      moment
        .duration(
          moment(endTime)
            .startOf('minutes')
            .diff(moment(startTime).startOf('minutes'))
        )
        .asMinutes(),
    [endTime, startTime]
  )

  // Input Form
  const [step, setStep] = useState<TEventInputStep>(
    event ? 'INFO' : 'VISIBILITY'
  )
  const { save, saving, saved, submitted } = useSaveEvent({
    eventId: event?.id,
    title,
    description,
    startTime,
    introductionTime,
    capacity,
    format,
    location,
    geolocation,
    collectiveId,
    discoverable:
      !!visibility &&
      [TVisibility.PUBLIC, TVisibility.COLLECTIVE_PUBLIC].includes(visibility),
    shareable,
    seriesId,
    recordingState
  })

  const canContinue = useCanContinue(
    visibility,
    collectiveId,
    step,
    format,
    title,
    description,
    location,
    saving,
    saved
  )

  const onBack = useCallback(() => {
    if (step === 'DETAILS') {
      setStep('INFO')
    } else if (step === 'INFO') {
      setStep('VISIBILITY')
    }
  }, [step])

  const onContinue = useCallback(() => {
    if (step === 'VISIBILITY') {
      setStep('INFO')
      return
    } else if (step === 'INFO') {
      setStep('DETAILS')
      return
    }

    save()
  }, [step, save])

  return (
    <BaseModal onClose={onClose} classNames="sm:max-w-2xl" disableBackdropClick>
      <div className="flex-1 flex flex-col overflow-hidden justify-between">
        <div className="px-6 py-6 space-y-10 flex-1 flex flex-col overflow-hidden">
          <h3 className="font-bold text-gray-800 text-lg">
            {event ? 'Edit' : 'Create'} an Event
          </h3>

          <EventInputStepper step={step} />

          {saving ? (
            <div className="flex-1 flex flex-col items-center space-y-10">
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size={'3x'}
                className="text-gray-700"
              />
              <p className="text-gray-700 text-sm">Saving Event...</p>
            </div>
          ) : saved ? (
            <div className="flex-1 flex flex-col items-center space-y-10">
              <FontAwesomeIcon
                icon={faCheck}
                size={'3x'}
                className="text-gray-700"
              />
              <p className="text-gray-700 text-sm">
                Event Saved! Taking you to the Event page now...
              </p>
            </div>
          ) : (
            <>
              {step === 'VISIBILITY' && (
                <EventVisibilityStep
                  editMode={!!event}
                  visibility={visibility}
                  setVisibility={setVisibility}
                  collectiveId={collectiveId}
                  setCollectiveId={setCollectiveId}
                  shareable={shareable}
                  setShareable={setShareable}
                />
              )}

              {step === 'INFO' && (
                <EventInfoStep
                  submitted={submitted}
                  format={format}
                  setFormat={setFormat}
                  location={location}
                  setLocation={setLocation}
                  setGeolocation={setGeolocation}
                  title={title}
                  setTitle={setTitle}
                  description={description}
                  setDescription={setDescription}
                  seriesId={seriesId}
                  setSeriesId={setSeriesId}
                />
              )}

              {step === 'DETAILS' && (
                <EventDetailsStep
                  restrictFieldChange={
                    event ? moment().isAfter(event.startTime) : false
                  }
                  startTime={startTime}
                  setStartTime={setStartTime}
                  endTime={endTime}
                  setEndTime={setEndTime}
                  capacity={capacity}
                  setCapacity={setCapacity}
                  format={format}
                  setRecordingState={setRecordingState}
                  recordingState={recordingState}
                />
              )}
            </>
          )}
        </div>

        <div className="bg-gray-50 flex justify-end py-4 px-6 my-4 space-x-3">
          {step === 'VISIBILITY' ? (
            <Button
              onClick={onClose}
              color="white"
              label="Cancel"
              className="w-32"
            />
          ) : (
            <Button
              onClick={onBack}
              color="white"
              label="Back"
              className="w-32"
              disabled={saving || saved}
            />
          )}

          <Button
            onClick={onContinue}
            disabled={!canContinue}
            label={step === 'DETAILS' ? 'Save' : 'Next'}
            className="w-32"
          />
        </div>
      </div>
    </BaseModal>
  )
}
