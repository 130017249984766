import StyledTooltip from 'components/Tooltip/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import CollectivePicker from 'components/CollectivePicker/CollectivePicker'
import { Toggle } from 'components/common'

export interface IProps {
  editMode: boolean
  collectiveId: Maybe<string>
  setCollectiveId: CallbackWithParam<Maybe<string>>
  shareable: boolean
  setShareable: CallbackWithParam<boolean>
}

function SelectACollective({
  editMode,
  collectiveId,
  setCollectiveId,
  shareable,
  setShareable
}: IProps) {
  return (
    <div>
      <p className="font-light text-gray-800 pb-3">Select a DAO</p>

      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 sm:gap-10">
        <div className="flex-1">
          <CollectivePicker
            disabled={editMode}
            value={collectiveId}
            onChange={setCollectiveId}
          />
        </div>

        <StyledTooltip
          arrow
          title="If checked, anyone with a link can join this event"
        >
          <Toggle
            label={
              <>
                {'Event is Shareable '}
                <FontAwesomeIcon icon={faInfoCircle} size="sm" />
              </>
            }
            checked={shareable}
            onChange={() => setShareable(!shareable)}
            disabled={editMode}
          />
        </StyledTooltip>
      </div>
    </div>
  )
}

export default SelectACollective
