import { StyledSelect } from 'components/common/Select'
import { EventSeries, useEventSeriesSuggestionQuery } from 'graphql/generated'

interface IProps {
  value: Maybe<string>
  onChange: CallbackWithParam<Maybe<EventSeries>>
}

const EventSeriesSelector: React.FC<IProps> = ({ value, onChange }) => {
  const { data } = useEventSeriesSuggestionQuery({
    variables: {
      query: ''
    }
  })

  const options = data?.eventSeriesSuggestion || []

  return (
    <StyledSelect<EventSeries, false>
      height={47}
      fontSize={14}
      label="Select an Event Series"
      isClearable
      options={options}
      value={options.find(x => x.id === value)}
      placeholder={`Select an Event Series`}
      onChange={val => onChange(val)}
      getOptionLabel={series => `${series.label}`}
    />
  )
}

export default EventSeriesSelector
