import cls from 'classnames'
import { RadioGroup } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { faCircle } from '@fortawesome/pro-regular-svg-icons'
import { useCurrentUser } from 'hooks'
import SelectACollective, {
  IProps as ISelcectCollectiveProps
} from './SelectACollective'
import { useEffect } from 'react'

export enum TVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  COLLECTIVE = 'COLLECTIVE',
  COLLECTIVE_PUBLIC = 'COLLECTIVE_PUBLIC'
}

interface IProps extends ISelcectCollectiveProps {
  editMode: boolean
  visibility: Maybe<TVisibility>
  setVisibility: CallbackWithParam<TVisibility>
}

function EventVisibilityStep({
  editMode,
  visibility,
  setVisibility,
  collectiveId,
  setCollectiveId,
  shareable,
  setShareable
}: IProps) {
  const viewer = useCurrentUser()

  useEffect(() => {
    setShareable(visibility !== TVisibility.COLLECTIVE)
  }, [visibility, setShareable])
  const getStyles = (checked: boolean, active: boolean) =>
    cls(
      checked ? 'border-transparent' : 'border-gray-300',
      active ? 'border-blue-500 ring-2 ring-blue-500' : '',
      'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none'
    )

  return (
    <div className="flex-1 flex flex-col overflow-auto px-1">
      <p className="font-light text-gray-800 pb-3">{`Choose your event's visibility. This will affect who can attend.`}</p>

      <RadioGroup
        value={visibility}
        onChange={setVisibility}
        disabled={editMode}
      >
        <div className="space-y-4">
          {viewer.canCreateDiscoverableEvents && (
            <RadioGroup.Option
              value={TVisibility.PUBLIC}
              className={({ checked, active }) => getStyles(checked, active)}
            >
              {({ active, checked }) => (
                <>
                  <div className="flex items-center space-x-4">
                    <FontAwesomeIcon
                      icon={checked ? faCheckCircle : faCircle}
                      className={checked ? 'text-blue' : 'text-gray-500'}
                    />

                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className="font-medium text-gray-800"
                      >
                        {`Public`}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as={'div'}
                        className="text-gray-500 overflow-hidden"
                      >
                        <p className="mt-2 font-thin">
                          {`Event will be available to any Upstream User, and may be featured in Discovery screens`}
                        </p>
                      </RadioGroup.Description>
                    </div>
                  </div>

                  <div
                    className={cls(
                      active ? 'border' : 'border-2',
                      checked ? 'border-blue-500' : 'border-transparent',
                      'absolute -inset-px rounded-lg pointer-events-none'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          )}

          <RadioGroup.Option
            value={TVisibility.PRIVATE}
            className={({ checked, active }) => getStyles(checked, active)}
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center space-x-4">
                  <FontAwesomeIcon
                    icon={checked ? faCheckCircle : faCircle}
                    className={checked ? 'text-blue' : 'text-gray-500'}
                  />

                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className="font-medium text-gray-800"
                    >
                      {`Private Event`}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="div"
                      className="text-gray-500 font-thin"
                    >
                      <p className="mt-2">
                        {`Guests can only RSVP by direct links`}
                      </p>
                    </RadioGroup.Description>
                  </div>
                </div>

                <div
                  className={cls(
                    active ? 'border' : 'border-2',
                    checked ? 'border-blue-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>

          <RadioGroup.Option
            value={TVisibility.COLLECTIVE}
            className={({ checked, active }) => getStyles(checked, active)}
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center space-x-4">
                  <FontAwesomeIcon
                    icon={checked ? faCheckCircle : faCircle}
                    className={checked ? 'text-blue' : 'text-gray-500'}
                  />

                  <div className="text-sm">
                    <RadioGroup.Label
                      as="p"
                      className="font-medium text-gray-800"
                    >
                      {`DAO Only Event`}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as={'div'}
                      className="text-gray-500 overflow-hidden"
                    >
                      <p className="mt-2 font-thin">
                        {`Event will only be visible to DAO members`}
                      </p>
                    </RadioGroup.Description>
                  </div>
                </div>

                <div
                  className={cls(
                    active ? 'border' : 'border-2',
                    checked ? 'border-blue-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>

          {viewer.canCreateDiscoverableEvents && (
            <RadioGroup.Option
              value={TVisibility.COLLECTIVE_PUBLIC}
              className={({ checked, active }) => getStyles(checked, active)}
            >
              {({ active, checked }) => (
                <>
                  <div className="flex items-center space-x-4">
                    <FontAwesomeIcon
                      icon={checked ? faCheckCircle : faCircle}
                      className={checked ? 'text-blue' : 'text-gray-500'}
                    />

                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className="font-medium text-gray-800"
                      >
                        {`DAO Public Event`}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as={'div'}
                        className="text-gray-500 overflow-hidden"
                      >
                        <p className="mt-2 font-thin">
                          {`Event will be available to any Upstream User, and may be featured in Discovery screens and DAO's events page`}
                        </p>
                      </RadioGroup.Description>
                    </div>
                  </div>

                  <div
                    className={cls(
                      active ? 'border' : 'border-2',
                      checked ? 'border-blue-500' : 'border-transparent',
                      'absolute -inset-px rounded-lg pointer-events-none'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          )}
        </div>
      </RadioGroup>

      {visibility &&
        [TVisibility.COLLECTIVE, TVisibility.COLLECTIVE_PUBLIC].includes(
          visibility
        ) && (
          <div className="pt-6">
            <SelectACollective
              editMode={editMode}
              collectiveId={collectiveId}
              setCollectiveId={setCollectiveId}
              shareable={shareable}
              setShareable={setShareable}
            />
          </div>
        )}
    </div>
  )
}

export default EventVisibilityStep
