import { EventFieldsFragment } from 'graphql/generated'
import { TVisibility } from './EventVisibilityStep'

export function getVisibilityForEvent(
  event?: Partial<EventFieldsFragment>
): Maybe<TVisibility> {
  if (!event) {
    return null
  } else if (event.collective && event.discoverable) {
    return TVisibility.COLLECTIVE_PUBLIC
  } else if (event.collective) {
    return TVisibility.COLLECTIVE
  } else if (event.discoverable) {
    return TVisibility.PUBLIC
  }

  return TVisibility.PRIVATE
}
