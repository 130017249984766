import React from 'react'
import cls from 'classnames'
import ProgressBar from './ProgressBar'
import { useBreakpoint } from 'hooks/useBreakpoint'
import Typography from './Typography'

export type Steps = {
  label: string
  count?: number | React.ReactNode
  disabled?: boolean
  description?: React.ReactNode
}

type Props = {
  steps: Steps[]
  currentStep: number
  setCurrentStep?: CallbackWithParam<number>
  vertical?: boolean
  children?: React.ReactNode
}

const PanelStepper: React.FC<Props> = ({
  steps,
  currentStep,
  setCurrentStep,
  vertical,
  children
}) => {
  const stepDetails = steps[currentStep]
  const currentNumber = currentStep + 1
  const { isMd } = useBreakpoint()

  if (vertical) {
    return (
      <div className={'relative mr-2'}>
        <div className="flex flex-col gap-6">
          {steps.map(({ label, disabled, count, description }, index) => {
            const isLast = index === steps.length - 1
            const isCurrentStep = index === currentStep
            const isDisabled = !isCurrentStep && disabled
            return (
              <div
                key={label}
                className={cls('relative flex', !disabled && 'cursor-pointer')}
              >
                {!isLast && (
                  <div className="absolute bottom-4 top-8 left-4 bg-gray-300 border-gray-300 border w-[2px] h-full" />
                )}

                <div
                  className={cls(
                    'shrink-0 w-8 h-8 rounded-full flex justify-center items-center mr-2',
                    isCurrentStep ? 'bg-blue' : 'bg-gray-500',
                    isDisabled && 'bg-viz-abstained'
                  )}
                >
                  <span className={cls('text-white text-md')}>{index + 1}</span>
                </div>

                <div className="mt-1.5">
                  <div className="flex items-center">
                    <Typography
                      fontWeight="light"
                      size="sm"
                      className={cls(isDisabled && 'text-gray-500')}
                    >
                      {label}
                    </Typography>

                    {!!count && (
                      <div className="ml-1 mt-[-13px] bg-[#0c5fe330] rounded-xl px-1.5 py-0.5 text-xs font-semibold">
                        {count}
                      </div>
                    )}
                  </div>

                  {(!!description || !!children) && (
                    <div className="mt-2">
                      <Typography>{description}</Typography>

                      {isCurrentStep ? children : null}
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  if (!isMd) {
    return (
      <div className="flex-col">
        <div className="flex flex-1 flex-row  mb-5">
          <div className="flex items-center border bg-white border-blue rounded-full justify-center align-middle text-center w-[42px]">
            <div className="flex items-center border text-blue bg-blue-100 rounded-full justify-center w-[30px] h-[30px]">
              {currentNumber}
            </div>
          </div>
          <div className="pl-4">
            <div className="text-sm font-thin leading-5 uppercase text-text-secondary">
              {`Step ${currentNumber}/${steps.length}`}
            </div>
            <div className="text-base font-semibold">{stepDetails.label}</div>
          </div>
        </div>
        <ProgressBar percentage={((currentStep + 1) / steps.length) * 100} />

        {!!steps[currentStep].description && (
          <Typography className="p-1">
            {steps[currentStep].description}
          </Typography>
        )}

        {children}
      </div>
    )
  }

  return (
    <div className={'flex justify-center items-center gap-x-2'}>
      {steps.map(({ label, disabled, count, description }, index) => {
        const isLast = index === steps.length - 1
        const isCurrentStep = index === currentStep
        const isDisabled = !isCurrentStep && disabled
        return (
          <React.Fragment key={label}>
            <div
              className={cls(
                'flex items-center relative',
                !disabled && 'cursor-pointer'
              )}
              onClick={() => {
                if (!disabled && setCurrentStep) {
                  setCurrentStep(index)
                }
              }}
            >
              <div
                className={cls(
                  'w-8 h-8 rounded-full flex justify-center items-center mr-2',
                  isCurrentStep ? 'bg-blue' : 'bg-gray-500',
                  !isCurrentStep && disabled && 'bg-viz-abstained'
                )}
              >
                <span className={cls('font-bold text-white text-md')}>
                  {index + 1}
                </span>
              </div>

              <Typography
                fontWeight="light"
                size="sm"
                className={cls(isDisabled && 'text-gray-500')}
              >
                {label}
              </Typography>

              {!!count && (
                <div className="ml-1 mt-[-13px] bg-[#0c5fe330] rounded-xl px-1.5 py-0.5 text-xs font-semibold">
                  {count}
                </div>
              )}

              {!!description && isCurrentStep && (
                <Typography size="sm" className="p-1">
                  {description}
                </Typography>
              )}

              {isCurrentStep ? children : null}
            </div>

            {!isLast && <div className="bg-gray-300 h-[1px] flex-grow" />}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default PanelStepper
