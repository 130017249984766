import PanelStepper from 'components/common/PanelStepper'
import { noop } from 'lodash'

export type TEventInputStep = 'VISIBILITY' | 'INFO' | 'DETAILS'

interface IProps {
  step: TEventInputStep
}

function EventInputStepper({ step }: IProps) {
  const currentStep = step === 'VISIBILITY' ? 0 : step === 'INFO' ? 1 : 2
  return (
    <PanelStepper
      steps={[
        { label: 'Event Visibility' },
        { label: 'Event Info' },
        { label: 'Event Details' }
      ]}
      setCurrentStep={noop}
      currentStep={currentStep}
    />
  )
}

export default EventInputStepper
