import useToasts from 'hooks/useToasts'

//styling
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faLink } from '@fortawesome/pro-solid-svg-icons'
import {
  faTwitter,
  faFacebookF,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons'

//helpers
import { shareLinkOnExternalSite } from 'lib/utils'

//graphql
import { EventFieldsFragment } from 'graphql/generated'
import DropdownMenu, {
  MenuItemProps
} from 'components/common/Dropdown/DropdownMenu'
import Button, { IProps as ButtonProps } from 'components/Button'
import { capitalize } from 'lodash'

export interface IProps extends ButtonProps {
  item: Pick<EventFieldsFragment, 'publicUrl' | '__typename'>
  icon?: IconProp
  fullTextLabel?: boolean
  message?: {
    twitter?: string
    facebook?: string
    linkedIn?: string
    dm?: string
  }
}

const ShareButton = ({
  item,
  icon,
  fullTextLabel,
  message = {},
  color = 'blue',
  size = 'sm',
  rounded = 'lg'
}: IProps) => {
  const { addToast } = useToasts()

  const handleCopyLink = () => {
    navigator.clipboard.writeText(item.publicUrl)
    addToast('Copied to clipboard')
  }

  const shareItems: MenuItemProps[] = [
    {
      type: 'button',
      label: 'Copy Link',
      icon: faLink,
      onClick: () => {
        handleCopyLink()
      }
    },
    {
      type: 'button',
      label: 'Twitter',
      icon: faTwitter,
      target: '_blank',
      onClick: () => {
        const url = shareLinkOnExternalSite('https://twitter.com/share', {
          url: item.publicUrl,
          text: message.twitter
        })
        open(url, '_blank')
      }
    },
    {
      type: 'button',
      label: 'LinkedIn',
      icon: faLinkedinIn,
      onClick: () => {
        const url = shareLinkOnExternalSite(
          'https://www.linkedin.com/cws/share',
          {
            url: item.publicUrl,
            //@ts-ignore
            mini: true, //required for linkedin
            title: message.linkedIn
          }
        )
        open(url, '_blank')
      }
    },
    {
      type: 'button',
      label: 'Facebook',
      icon: faFacebookF,
      onClick: () => {
        const url = shareLinkOnExternalSite(
          'https://www.facebook.com/sharer/sharer.php',
          {
            u: item.publicUrl,
            quote: message.facebook
          }
        )
        open(url, '_blank')
      }
    }
  ]

  const buttonText = `Share${
    icon || fullTextLabel
      ? ` ${capitalize(item.__typename.replace('_', ' '))}`
      : ''
  }`

  return (
    <DropdownMenu
      button={
        <Button
          label={buttonText}
          color={color}
          icon={icon}
          size={size}
          rounded={rounded}
        />
      }
      items={shareItems}
    />
  )
}

export default ShareButton
