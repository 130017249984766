type Props = {
  percentage: number
}

const ProgressBar: React.FC<Props> = ({ percentage }) => {
  return (
    <div className="overflow-hidden rounded-full bg-gray-200">
      <div
        className="h-2 rounded-full bg-blue"
        style={{ width: `${percentage}%` }}
      />
    </div>
  )
}

export default ProgressBar
