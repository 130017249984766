import EventSeriesSelector from 'components/EventSeriesSelector/EventSeriesSelector'
import language from 'services/Language'
import { EventFormat, GeolocationObjectInput } from 'graphql/generated'
import { useState } from 'react'
import { TEvent } from 'hooks/useControlledInput'
import LocationInput from 'components/LocationInput/LocationInput'
import { StyledSelect } from 'components/common/Select'
import TextField from 'components/common/TextField'
import { useCurrentUser } from 'hooks'
interface IProps {
  submitted: boolean
  format: EventFormat
  setFormat: CallbackWithParam<EventFormat>
  location: Maybe<string>
  setLocation: CallbackWithParam<string>
  setGeolocation: CallbackWithParam<GeolocationObjectInput>
  title: string
  setTitle: CallbackWithParam<TEvent>
  description: string
  setDescription: CallbackWithParam<TEvent>
  seriesId: Maybe<string>
  setSeriesId: CallbackWithParam<Maybe<string>>
}

const FormatOptions = [
  {
    value: EventFormat.MIXER,
    label: language.events.formats.mixer
  },
  {
    value: EventFormat.IN_PERSON,
    label: language.events.formats.inPerson
  }
]

function EventInfoStep({
  submitted,
  format,
  setFormat,
  location,
  setLocation,
  setGeolocation,
  title,
  setTitle,
  description,
  setDescription,
  seriesId,
  setSeriesId
}: IProps) {
  const viewer = useCurrentUser()
  const [showSeriesSelector, setShowSeriesSelector] = useState(!!seriesId)

  return (
    <div className="flex-1 flex flex-col overflow-auto space-y-5">
      <StyledSelect<{ value: string; label: string }, false>
        label="Select an Event Format"
        height={47}
        options={FormatOptions}
        placeholder="Format"
        value={FormatOptions.find(option => option.value === format)}
        onChange={val => setFormat(val?.value as EventFormat)}
      />

      {format === EventFormat.IN_PERSON && (
        <LocationInput
          value={location || ''}
          onChange={setLocation}
          onSelect={(location, coordinates) => {
            setLocation(location)
            setGeolocation({
              latitude: coordinates.lat,
              longitude: coordinates.lng
            })
          }}
          error={submitted && (!location || !location.length)}
        />
      )}

      <TextField
        label="Title"
        value={title}
        onChange={setTitle}
        required
        error={submitted && (!title || !title.length)}
        placeholder="Enter an Event title"
      />

      <TextField
        label="Description"
        multiple
        value={description}
        onChange={setDescription}
        error={submitted && (!description || !description.length)}
        required
        placeholder="Enter an Event description"
      />

      {showSeriesSelector ? (
        <EventSeriesSelector
          value={seriesId}
          onChange={change => {
            setSeriesId(change?.id)
            if (!change) {
              setShowSeriesSelector(false)
            }
          }}
        />
      ) : viewer.canCreateDiscoverableEvents ? (
        <div className="text-right">
          <p
            className="font-light text-sm text-blue cursor-pointer -mt-1 hover:underline"
            onClick={() => setShowSeriesSelector(true)}
          >{`+ Assign to Event Series`}</p>
        </div>
      ) : null}
    </div>
  )
}

export default EventInfoStep
