import { EventQueryResult } from 'graphql/generated'
import React, {
  createContext,
  useContext,
  PropsWithChildren,
  useEffect
} from 'react'
import { useAnalyticsContext } from './AnalyticsContext'

interface EventContextProps {
  event: EventQueryResult['event']
}

interface EventContextShape {
  event: EventQueryResult['event']
}

const EventContext = createContext<EventContextShape>({} as EventContextShape)

export const useEventContext = () => useContext(EventContext)

export function EventProvider({
  event,
  children
}: PropsWithChildren<EventContextProps>) {
  const { addEventProperties, removeEventProperties } = useAnalyticsContext()
  useEffect(() => {
    addEventProperties({ event })
  }, [addEventProperties, event])

  useEffect(() => {
    return () => {
      removeEventProperties(['event'])
    }
  }, [removeEventProperties])

  return (
    <EventContext.Provider value={{ event }}>{children}</EventContext.Provider>
  )
}
