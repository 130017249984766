import { useMemo } from 'react'
import useToasts from 'hooks/useToasts'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons'
import {
  EventState,
  EventRegistrationRSVP,
  useRSVPToEventMutation,
  EventFieldsFragment
} from 'graphql/generated'
import { useGatedCallback, useQueryErrorHandler } from 'hooks'
import Button, { IProps as IButtonProps } from 'components/Button'
import DropdownMenu from 'components/common/Dropdown/DropdownMenu'
import { doesUserHaveEventPermissions } from './EnterEventButton'
import { getVisibilityForEvent } from '../EventInputModal/helpers'

interface IProps extends IButtonProps {
  event: EventFieldsFragment
}

function RSVPButton({ event, ...rest }: IProps) {
  const hasEnded = event.state === EventState.ENDED
  const { addToast } = useToasts()

  const onError = useQueryErrorHandler('Failed to RSVP. Please try again.')
  const [rsvpToEvent, { loading }] = useRSVPToEventMutation({
    onError: err => {
      onError(err)
      window.analytics?.trackAll(`Event - Signup - Error`, {
        eventId: event.id,
        eventName: event.title,
        type: getVisibilityForEvent(event)
      })
    },
    onCompleted: () => {
      addToast(
        `You're all set. We'll send you a Calendar invite in just a moment.`
      )
      window.analytics?.trackAll(`Event - Signup - Complete`, {
        eventId: event.id,
        eventName: event.title,
        type: getVisibilityForEvent(event)
      })
    }
  })

  const authGate = useGatedCallback(
    `Want to attend ${event.title}? Sign up & join Upstream.`,
    `Once you join Upstream, you'll be able to RSVP.`,
    `/auth/signup?eventId=${event.id}`
  )

  const callback = authGate(() => {
    if (
      // can't RSVP to an event that's ended
      hasEnded ||
      // owner must stay RSVP'd by default
      (event.isOwner && event.rsvp === EventRegistrationRSVP.YES) ||
      // user already has a pending RSVP ()
      event.rsvp === EventRegistrationRSVP.PENDING
    ) {
      return
    }

    rsvpToEvent({
      variables: {
        eventId: event.id,
        rsvp: EventRegistrationRSVP.YES
      }
    })
    window.analytics.trackAll(`RSVPd Event`, {
      eventId: event.id,
      eventName: event.title
    })
  })

  const handleRsvpYesClick = (mouseEvent: React.MouseEvent) => {
    mouseEvent.preventDefault()
    callback()
  }

  const rsvpButtonLabel = useMemo(() => {
    switch (event.rsvp) {
      case EventRegistrationRSVP.YES:
        return 'Attending'
      case EventRegistrationRSVP.PENDING:
        return 'RSVP Pending'
      default:
        return 'RSVP'
    }
  }, [event.rsvp])

  const showRSVPButton = doesUserHaveEventPermissions(event) && !hasEnded

  if (!showRSVPButton) {
    return null
  }

  if (event.rsvp !== EventRegistrationRSVP.YES) {
    return (
      <Button
        label="RSVP"
        onClick={handleRsvpYesClick}
        color="blue"
        labelClassName="font-bold"
        loading={loading}
        disabled={loading || event.rsvp === EventRegistrationRSVP.PENDING}
        size={'lg'}
        {...rest}
      />
    )
  }

  return (
    <DropdownMenu
      button={
        <Button
          label={rsvpButtonLabel}
          color="blue"
          loading={loading}
          disabled={loading}
          icon={faChevronDown}
          size={'md'}
          {...rest}
        />
      }
      items={[
        !event.isOwner && {
          type: 'button',
          label: 'Un-RSVP',
          icon: faCalendarTimes,
          onClick: () =>
            rsvpToEvent({
              variables: {
                eventId: event.id,
                rsvp: EventRegistrationRSVP.NO
              }
            })
        }
      ]}
    />
  )
}

export default RSVPButton
